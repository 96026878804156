import type { UserResourceDTO } from '~/dto/user-resource-dto';
import { enumApplicationPage, enumBroadcastAudienceType, enumBroadcastMedium } from '~/genql';
import type {
  BroadcastAudienceType,
  ApplicationPage,
  BuildingAudienceParams,
  EventAudienceParams,
  SpecificUsersAudienceParams,
  InterestGroupsAudienceParams,
  BroadcastMedium,
  CommunityAudienceParams,
} from '~/genql';
import type { Building } from '~/genql-legacy';
import type { ScheduledRepeat } from '~/utils/cron.server';

export interface ScheduledBroadcast {
  startDate: string;
  repeat?: ScheduledRepeat;
  nextNotificationDate?: string;
}

interface BaseNotificationsFormInitialValuesDTO {
  medium?: BroadcastMediumOption;
  mediums?: BroadcastMedium[];
  imageId?: string;
  buildings?: Pick<Building, 'id' | 'name'>[];
  title?: string;
  message?: string;
  deepLink?: {
    applicationPage: ApplicationPage;
    description: string | null;
    entityId: string | null;
  };
  scheduledBroadcast?: ScheduledBroadcast;
  residents?: UserResourceDTO[] | null;
}

export interface BuildingAudience extends BaseNotificationsFormInitialValuesDTO {
  audience: 'BUILDING';
  audienceParams: BuildingAudienceParams;
}

export interface EventAudience extends BaseNotificationsFormInitialValuesDTO {
  audience: 'EVENT_TYPE';
  audienceParams: EventAudienceParams;
}
export interface GroupAudience extends BaseNotificationsFormInitialValuesDTO {
  audience: 'INTEREST_GROUP';
  audienceParams: InterestGroupsAudienceParams;
}

export interface ResidentsAudience extends BaseNotificationsFormInitialValuesDTO {
  audience: 'SPECIFIC_MEMBERS';
  audienceParams: SpecificUsersAudienceParams;
}

export interface CommunityAudience extends BaseNotificationsFormInitialValuesDTO {
  audience: 'COMMUNITY';
  audienceParams: CommunityAudienceParams;
}

export type NotificationsFormInitialValuesDTO =
  | BuildingAudience
  | EventAudience
  | ResidentsAudience
  | GroupAudience
  | CommunityAudience;

export enum NotificationsFormAudience {
  ENTIRE_PORTFOLIO = 'All buildings',
  BUILDINGS = 'Buildings',
  SPECIFIC_MEMBERS = 'Specific residents',
  EVENT_TYPE = 'Event attendees',
}

export enum NotificationsFormScheduledRepeat {
  DO_NOT_REPEAT = '',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
}

export enum BroadcastCombinationMedium {
  APP_AND_EMAIL = 'APP_AND_EMAIL',
}

export type BroadcastMediumOption = BroadcastMedium | BroadcastCombinationMedium;

export const mapServerMediumToUI: Record<BroadcastMediumOption | string, string> = {
  [enumBroadcastMedium.EMAIL]: 'Email',
  [enumBroadcastMedium.MOBILE_APP]: 'App notification',
  [BroadcastCombinationMedium.APP_AND_EMAIL]: 'App + Email',
};

export enum NotificationsFormAudienceField {
  COMMUNITY = 'All buildings',
  BUILDING = 'Specific buildings',
  EVENT_TYPE = 'Event attendees',
  INTEREST_GROUP = 'Interest group',
  SPECIFIC_MEMBERS = 'Specific residents',
}

export const mapServerAudienceToUI: Record<BroadcastAudienceType, NotificationsFormAudienceField> =
  {
    [enumBroadcastAudienceType.BUILDING]: NotificationsFormAudienceField.BUILDING,
    [enumBroadcastAudienceType.EVENT_TYPE]: NotificationsFormAudienceField.EVENT_TYPE,
    [enumBroadcastAudienceType.INTEREST_GROUP]: NotificationsFormAudienceField.INTEREST_GROUP,
    [enumBroadcastAudienceType.SPECIFIC_MEMBERS]: NotificationsFormAudienceField.SPECIFIC_MEMBERS,
    [enumBroadcastAudienceType.COMMUNITY]: NotificationsFormAudienceField.COMMUNITY,
  };

export function isSupportedAudienceType(audienceType: BroadcastAudienceType) {
  return [
    enumBroadcastAudienceType.EVENT_TYPE,
    enumBroadcastAudienceType.BUILDING,
    enumBroadcastAudienceType.SPECIFIC_MEMBERS,
    enumBroadcastAudienceType.INTEREST_GROUP,
    enumBroadcastAudienceType.COMMUNITY,
  ].includes(audienceType);
}

export const FormSearchParams = {
  Channel: 'channel',
  Audience: 'audience',
  Category: 'category',
  Event: 'event',
  HasDeepLink: 'has-deep-link',
  ApplicationPage: 'application-page',
  DeepLinkId: 'deep-link-id',
  ScheduledMessage: 'scheduled-message',
  Repeat: 'repeat',
  Hood: 'hood',
  UserId: 'user-id',
  MoveInStatus: 'move-in-status',
  leaseStartDate: 'lease-start-date',
  leaseEndDate: 'lease-end-date',
  dialogType: 'dialog-type',
  notificationId: 'notification-id',
  groupId: 'group-id',
  residentId: 'resident-id',
  view: 'view',
  modalAction: 'modal-action',
} as const;

export const ctaTextByApplicationPage = {
  [enumApplicationPage.EVENT]: 'Event screen',
  [enumApplicationPage.EVENTS_PAGE]: 'View events',
  [enumApplicationPage.INVOICES]: 'Your invoices',
  [enumApplicationPage.MY_TICKETS]: 'Service requests',
  [enumApplicationPage.PLACE]: 'View location',
  [enumApplicationPage.PROFILE]: 'Your profile',
  [enumApplicationPage.SERVICE]: 'Market service',
  [enumApplicationPage.USER]: 'View profile',
};
