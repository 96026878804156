import { useEffect, useState } from 'react';

export const useIsMobile = (initialIsMobile?: boolean): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(() => {
    if (typeof window !== 'undefined') {
      return window.matchMedia('(max-width: 768px)').matches;
    }
    return initialIsMobile ?? false;
  });

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const updateIsMobile = () => {
      setIsMobile(window?.matchMedia('(max-width: 768px)').matches);
    };

    window?.addEventListener('resize', updateIsMobile);
    return () => {
      window?.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  return isMobile;
};
